import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
import DatePicker from 'vue2-datepicker';
export default {
    name: 'hostToHostBankTransfer',
    components: {
        DatePicker
    },
    data() {
        return {
            loader: false,
            unsubscribe: null,
            perPage: commonHelper.perPageRecord,
            pageOptions: commonHelper.getPageOption(),
            currentPage: 1,
            totalRows: null,
            showhostToHostBankTransferModal: false,
            hostToHostBankTransferDetails: null,
            parent_value_set_id: null,
            leName: {
                value: null,
                text: null
            },
            ouName: {
                value: null,
                text: null
            },
            sourceName: null,
            showAdvSearchModal: false,
            showValueSetModal: false
        };
    },
    mounted() {
        this.unsubscribe = this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'shared/setActionName') {
                const actionName = state.shared.actionName;
                if (actionName === 'add') {
                    this.hideBatchPaymentModal(true);
                    this.batchPaymentDetails = null;
                }
            }
        });
    },
    methods: {
        openValueSetModal(vsetCode) {
            if (vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
                this.parent_value_set_id = this.leName.value;
            }
            this.vsetCode = vsetCode;
            this.showValueSetModal = true;
            this.setTimeVsetCode = setTimeout(() => {
                const vSetData = {
                    valueSetName: vsetCode,
                    multiFlag: null
                };
                this.eventBus.$emit('valueSetCode', vSetData);
            }, 0);
        },
        selectedvalueSet(item) {
            if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
                this.leName = {
                    value: item.org_id,
                    text: item.org_name
                };
                this.ouName = {
                    value: null,
                    text: null
                }
            } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
                this.ouName = {
                    value: item.org_id,
                    text: item.org_name
                };
            }
        },
        closeValueSetModal() {
            this.showValueSetModal = false;
        },
        hideHostToHostBankTransferModal(flag) {
            this.showHostToHostBankTransferModal = flag;
        },
        showHideAdvSearchModal(flag) {
            this.showAdvSearchModal = flag;
        },
        clear() {
            this.leName = {
                value: null,
                text: null
            };
            this.ouName = {
                value: null,
                text: null
            };
            this.parent_value_set_id = null;
            this.sourceName = null;
        },
        clearVsetValues(vsetCode){
            if (vsetCode === 'LEGAL_ENTITY_LIST') {
                this.leName = {
                    value: null,
                    text: null
                };
                this.ouName = {
                    value: null,
                    text: null
                }
            } else if (this.vsetCode === 'OU_LIST') {
                this.ouName = {
                    value: null,
                    text: null
                };
            }
        }
    },
    beforeDestroy() {
        this.unsubscribe();
    }
};